import { Injectable } from '@angular/core';
import { VehicledataService } from '../vehicledata/vehicledata.service';
import { TranslateService } from '@ngx-translate/core';
import { DataGroupsConstants } from '@utils/constants/data-groups.constants';
import { MissingDataService } from '@services/missing-data/missing-data.service';
import { PlaceholderPipe } from '@utils/pipes/placeholder/placeholder.pipe';
import { audiLogo } from '@assets/pdf/audiLogo.const';
import { vwLogo } from '@assets/pdf/vwLogo.const';
import { seatLogo } from '@assets/pdf/seatLogo.const';
import { skodaLogo } from '@assets/pdf/skodaLogo.const';
import { gskLogo } from '@assets/pdf/gskLogo.const';
import { vwagLogo } from '@assets/pdf/vwagLogo.const';
import { internalBanner } from '@assets/pdf/internalBanner.const';
import { DatePipe } from '@angular/common';
import { MapService } from '@services/map/map.service';
import { IVehicleDataGroup } from '@utils/interfaces/key-readout/vehicle-data-group.interface';
import {
  IDiagnosticTroubleCodes
} from '@utils/interfaces/key-readout/service-key-data/diagnostic-trouble-code.interface';
import { IFieldIdValuePair } from '@utils/interfaces/key-readout/id-value-pair.interface';
import { IVehicleData } from '@utils/interfaces/key-readout/vehicle-data/vehicle-data.interface';
import { IVehicleColor } from '@utils/interfaces/key-readout/vehicle-data/vehicle-color.interface';
import { IWarnings } from '@utils/interfaces/key-readout/service-key-data/warning.interface';
import { TranslationPathPDFConstants } from '@utils/constants/translation-path-pdf.constants';
import { IFieldIdValueNote } from '../../utils/interfaces/key-readout/id-value-note.interface';

/**
 * This service generates the content of the PDF
 */
@Injectable({
  providedIn: 'root',
})
export class PdfContentService {
  // Data to be displayed in the PDF file
  data!: IVehicleDataGroup;

  public usedTranslations = {};

  /**
   * Creates an instance of PdfContentService
   *
   * @param vehicleDataService Service for the group service key information
   * @param translate communication with the internationalization (i18n) library
   * @param placeholder Use a placeholder if no data is available
   * @param datePipe formats a date value according to locale rules
   * @param mapService map functions
   * @param missingDataService Checks whether the incoming data are empty
   */
  constructor(
    private vehicleDataService: VehicledataService,
    private translate: TranslateService,
    private placeholder: PlaceholderPipe,
    private datePipe: DatePipe,
    private mapService: MapService,
    private missingDataService: MissingDataService
  ) {
    this.vehicleDataService.replaySubject.subscribe((data) => {
      this.data = data;
    });
  }

  public getServiceSchedulingActivated(serviceSchedulingActivated): string {
    if (serviceSchedulingActivated === true)
      return this.usedTranslations['key-data']['data']['field-id-service-scheduling-activated']['yes'];
    else if (serviceSchedulingActivated === false) {
      return this.usedTranslations['key-data']['data']['field-id-service-scheduling-activated']['no'];
    } else if (serviceSchedulingActivated === "not capable")
      return this.usedTranslations['key-data']['data']['field-id-service-scheduling-activated']['not-capable'];
    else{
      return this.usedTranslations['key-data']['data']['field-id-service-scheduling-activated']['no-information'];
    }
  }

  /**
   * Creating the pdf header and footer
   */
  public createPdfShell(): void {
    document
      .getElementById('pdf-header-gskLogo')!
      .setAttribute('src', `data:image/png;base64, ${gskLogo}`);
    document
      .getElementById('pdf-header-internalBanner')!
      .setAttribute('src', `data:image/png;base64, ${internalBanner}`);
    document
      .getElementById('pdf-footer-vwagLogo')!
      .setAttribute('src', `${vwagLogo}`);
  }

  /**
   * Recieves and set the data for print vew and download when its on historical data
   *
   * @param groupServiceKey vehicle data
   */
  public dataFromHistoricalData(groupServiceKey: IVehicleDataGroup): void {
    this.data = groupServiceKey;

  }

  /**
   * Creating the content for the key data pdf
   */
  public createKeyDataPDF(usedTranslations: any): void {
    this.usedTranslations = usedTranslations;
    // title
    // Create PDF header
    this.createKeyDataPdfHeader(this.data);

    // vehicle data
    // no check for missing data, as the info must be there, otherwise the pdf generation is not possible.
    this.fillPdfWithObject(
      this.data.vehicleData.data,
      TranslationPathPDFConstants.vehicleData,
      'vehicleData'
    );

    // vehicle data - color
    this.createColorTable(this.data.vehicleData.color);

    // service key data title
    document.getElementById('pdf-service-key-data-title')!.innerHTML =
      this.usedTranslations['pdf']['service-key-data'];
    // Service Scheduling Activated
    document.getElementById('pdf-keyData-serviceSchedulingActivated-fieldname')!.innerHTML =
      this.usedTranslations['key-data']['data']['field-id-service-scheduling-activated']['label'];
    document.getElementById('pdf-keyData-serviceSchedulingActivated-value')!.innerHTML =
      this.getServiceSchedulingActivated(this.data.serviceKeyData.serviceSchedulingActivated?.value);

    // distances - mileage
    if (this.data.serviceKeyData?.mileageNote?.note != null) {
      this.data.serviceKeyData.mileage.value = this.data.serviceKeyData.mileageNote.note;
    }
    this.setOrRemoveTables(
      'mileage',
      this.data.serviceKeyData.mileage,
      TranslationPathPDFConstants.distances 
    );

    // Populate the mileageTimestamp field
    if (this.data.serviceKeyData?.mileageTimestamp) {
      this.fillPdfWithObject(
        this.data.serviceKeyData.mileageTimestamp,
        TranslationPathPDFConstants.distances,
        'mileageTimestamp'
      );
    } else {
      // Remove the mileageTimestamp row if data is not available
      const mileageTimestampRow = document.getElementById('pdf-keyData-mileageTimestamp-fieldname')?.parentElement;
      if (mileageTimestampRow) {
        mileageTimestampRow.remove();
      }
    }
    
    // Populate the mileageInfo field
   if (this.data.serviceKeyData?.mileageInfo?.note) {
    var mileageInfo= {'fieldId':'field-id-mileage-info', 'value': this.data.serviceKeyData?.mileageInfo.note, 'unit': ' '}
          this.fillPdfWithObject(
            mileageInfo,
            TranslationPathPDFConstants.distances,
            'mileageInfo'
          );
    } 


    // service interval display title
    document.getElementById('pdf-service-interval-display-title')!.innerHTML =
      this.usedTranslations['pdf']['service-interval-display'];

    //  distances - serviceIntervalDisplay
    this.setOrRemoveTables(
      'serviceIntervalDisplay',
      this.data.serviceKeyData.serviceIntervalDisplay,
      TranslationPathPDFConstants.distances
    );

    // fluid levels title
    document.getElementById('pdf-fluid-levels-title')!.innerHTML =
      this.usedTranslations['pdf']['fluid-levels'];

    //  fluid level
    this.setOrRemoveTables(
      'fluidLevels',
      this.data.serviceKeyData.fluidLevels,
      TranslationPathPDFConstants.fluidLevels
    );

    // remaining range of drive title
    document.getElementById('pdf-remaining-ranges-of-drive-title')!.innerHTML =
      this.usedTranslations['pdf']['remaining-ranges-of-drive'];

    // remaining range
    this.setOrRemoveTables(
      'remainingRangesOfDrives',
      this.data.serviceKeyData.remainingRangesOfDrives,
      TranslationPathPDFConstants.fluidLevels
    );

    // adBlue
    this.setOrRemoveTables(
      'adBlue',
      this.data.serviceKeyData.adBlue,
      TranslationPathPDFConstants.fluidLevels
    );

    // oil norm title
    document.getElementById('pdf-oil-norm-title')!.innerHTML =
      this.usedTranslations['pdf']['oil-norm'];

    // oilNorm
    this.setOrRemoveTables(
      'oilNorm',
      this.data.serviceKeyData.oilNorm,
      TranslationPathPDFConstants.fluidLevels
    );

    // warnings
    const warningMessagesActive =
      this.data.serviceKeyData.warningMessagesActive;
    const warningMessagesHistory =
      this.data.serviceKeyData.warningMessagesHistory;
    // check if the warning messages are available
    if (
      this.missingDataService.isDataEmpty(
        {
          warningMessagesActive: warningMessagesActive,
          warningMessagesHistory: warningMessagesHistory,
        },
        DataGroupsConstants.warningsData
      )
    ) {
      if (document.getElementById('pdf-keyData-warnings')) {
        document.getElementById('pdf-keyData-warnings')!.remove();
      }
    } else {
      this.createWarningTable(warningMessagesActive, 'warningMessagesActive');
      this.createWarningTable(warningMessagesHistory, 'warningMessagesHistory');
    }

    // battery information title
    document.getElementById('pdf-battery-information-title')!.innerHTML =
      this.usedTranslations['pdf']['battery-information'];

    // energy consumer and hints
    const batteryChargeLevel = this.data.serviceKeyData.batteryChargeLevel;
    const electricVehicleInformation =
      this.data.serviceKeyData.electricVehicleInformation;
    // check if the energy consumer and hints infos are available
    if (
      this.missingDataService.isDataEmpty(
        {
          batteryChargeLevel: batteryChargeLevel,
          electricVehicleInformation: electricVehicleInformation,
        },
        DataGroupsConstants.batteryInformationData
      )
    ) {
      if (document.getElementById('pdf-keyData-batteryInformationData')) {
        document.getElementById('pdf-keyData-batteryInformationData')!.remove();
      }
    } else {
      this.fillPdfWithObject(
        batteryChargeLevel,
        TranslationPathPDFConstants.batteryInformation,
        'batteryChargeLevel'
      );
      this.fillPdfWithObject(
        electricVehicleInformation.capacity,
        TranslationPathPDFConstants.batteryInformation,
        'electricVehicleInformation'
      );
    }

    // energy consumer and hints
    const energyConsumers = this.data.serviceKeyData.energyConsumers;
    const energyHints = this.data.serviceKeyData.energyHints;
    // check if the energy consumer and hints infos are available
    if (
      this.missingDataService.isDataEmpty(
        { energyConsumers: energyConsumers, energyHints: energyHints },
        DataGroupsConstants.energyConsumerHintsData
      ) &&
      document.getElementById('pdf-keyData-energyConsumerHints')
    ) {
      document.getElementById('pdf-keyData-energyConsumerHints')!.remove();
    } else {
      // vehicle data - color code
      this.fillPdfWithObject(
        energyConsumers,
        TranslationPathPDFConstants.energyConsumerHints,
        'energyConsumers'
      );
      this.fillPdfWithObject(
        energyHints,
        TranslationPathPDFConstants.energyConsumerHints,
        'energyHints'
      );
    }

    // emission - wltp
    this.setOrRemoveTables(
      'wltpData',
      this.data.emissionData.wltpData,
      TranslationPathPDFConstants.emission,
      'wltpData'
    );

    // emission - nedc
    this.setOrRemoveTables(
      'nedcData',
      this.data.emissionData.nedcData,
      TranslationPathPDFConstants.emission,
      'nedcData'
    );

    // dtc (event memory entries) title
    document.getElementById('pdf-event-memory-entries-title')!.innerHTML =
      this.usedTranslations['pdf']['event-memory-entries'];

    // DTCs
    const diagnosticTroubleCodes =
      this.data.serviceKeyData.diagnosticTroubleCodes;
    if (
      this.missingDataService.isDataEmpty(
        { diagnosticTroubleCodes: diagnosticTroubleCodes },
        DataGroupsConstants.diagnosticData
      ) &&
      document.getElementById('pdf-keyData-diagnosticTroubleCodes')
    ) {
      document.getElementById('pdf-keyData-diagnosticTroubleCodes')!.remove();
    } else {
      this.createDtcTable(diagnosticTroubleCodes);
    }
  }

  /**
   * Checking whether a table must be filled or removed
   *
   * @param tableID Superordinate tables ID
   * @param object Contains the information to be displayed
   * @param translationPath Prefixes for the mapping of the translations in the TranslationPipe
   * @param cellPräfixID prefix for the id of an HTML element (if elements occur twice, this can be used to make a distinction)
   */
  setOrRemoveTables(
    tableID: string,
    object: any,
    translationPath: TranslationPathPDFConstants,
    cellPräfixID?: string
  ): void {
    // check if the object is available
    if (
      !this.missingDataService.valueAvailableCheck(object, false) &&
      document.getElementById('pdf-keyData-' + tableID)
    ) {
      document.getElementById('pdf-keyData-' + tableID)!.remove();
    } else {
      this.fillPdfWithObject(object, translationPath, tableID, cellPräfixID);
    }
  }

  /**
   * iterate through the object and fill the table cells
   *
   * @param object Contains the information to be displayed
   * @param translationPath Prefixes for the mapping of the translations in the TranslationPipe
   * @param tableID Superordinate tables ID
   * @param cellPräfixID prefix for the id of an HTML element (if elements occur twice, this can be used to make a distinction)
   */
  fillPdfWithObject(
    object: any,
    translationPath: TranslationPathPDFConstants,
    tableID: string,
    cellPräfixID?: string
  ): void {
    let key: keyof typeof object;
    for (key in object) {
      if (key === 'fieldId') {
        this.returnTableCellValue(object, translationPath, tableID);
      } else if (key !== 'value' && key !== 'unit') {
        this.returnTableCellValue(
          object[key],
          translationPath,
          key,
          cellPräfixID
        );
      }
    }
  }


  /**
   * fill the table cell
   *
   * @param idValuePair to be mapped fieldId - value pair
   * @param translationPath Prefixes for the mapping of the translations in the TranslationPipe
   * @param fieldId the fieldId to be filled
   * @param cellPräfixID prefix for the id of an HTML element (if elements occur twice, this can be used to make a distinction)
   */
  returnTableCellValue(
    idValuePair: IFieldIdValuePair,
    translationPath: TranslationPathPDFConstants,
    fieldId: string,
    cellPräfixID?: string
  ): void {
    const id = cellPräfixID ? cellPräfixID + '-' + fieldId : fieldId;
    const fieldNameHtml = document.getElementById('pdf-keyData-' + id + '-fieldname');
    const valueHtml = document.getElementById('pdf-keyData-' + id + '-value');
  
    if (fieldNameHtml) {
      fieldNameHtml.innerHTML = this.usedTranslations['key-data'][translationPath][idValuePair.fieldId];
    }
  
    if (valueHtml) {
      let displayValue: string;
      if (idValuePair.fieldId === 'field-id-mileage-timestamp') {
        const value = idValuePair.value;
        if (typeof value === 'string' || typeof value === 'number' || value instanceof Date) {
          displayValue = this.datePipe.transform(value, 'yyyy-MM-dd HH:mm') || '';
        } else {
          displayValue = '-';
        }
      } else {
        displayValue = this.placeholder.transform(idValuePair.value);
      }
      valueHtml.innerHTML = displayValue + (idValuePair.unit ? ' ' + idValuePair.unit : '');
    }
  }
  

  /**
   * Filling the key data pdf heading
   *
   * @param gskResponse Contains the information to be displayed
   */
  createKeyDataPdfHeader(gskResponse: IVehicleDataGroup): void {
    const data: IVehicleData = gskResponse.vehicleData.data;
    const modelYear = data.modelYear.value;
    const modelName = data.modelName.value;
    const brandCode = data.brandCode.value;
    const timestamp = gskResponse.serviceKeyData.timestamp;


    let base64Logo = '';

    // mapping the brandCode to display the corresponding brand logo
    switch (brandCode) {
      case 'A':
        base64Logo = audiLogo;
        break;
      case 'C':
        base64Logo = skodaLogo;
        break;
      case 'S':
        base64Logo = seatLogo;
        break;
      default:
        base64Logo = vwLogo;
        break;
    }
    document
      .getElementById('pdf-keyData-brandLogo')!
      .setAttribute('src', `${base64Logo}`);
    document.getElementById('pdf-keyData-title')!.innerHTML =
      '| ' + modelName + ' | ' + modelYear;
    const lastReadoutOnLabel = this.usedTranslations['key-data']['key-data-subtitle']
    document.getElementById('pdf-keyData-readout-on')!.textContent = lastReadoutOnLabel + ' ' + this.datePipe.transform(timestamp, 'yyyy-MM-dd HH:mm');
  }

  /**
   * Checking whether the color table must be filled or removed
   *
   * @param vehicleColor Contains the information to be displayed
   */
  createColorTable(vehicleColor: IVehicleColor): void {
    // check if the vehicle color info is available
    if (
      this.missingDataService.isDataEmpty(
        { color: vehicleColor },
        DataGroupsConstants.colorInformationData
      )
    ) {
      document.getElementById('pdf-keyData-color')!.remove();
    } else {
      //  vehicle data - color code
      this.fillPdfWithObject(
        vehicleColor.exterior,
        TranslationPathPDFConstants.colorInformation,
        'colorExterior',
        'colorExterior'
      );
      this.fillPdfWithObject(
        vehicleColor.interior,
        TranslationPathPDFConstants.colorInformation,
        'colorInterior',
        'colorInterior'
      );
    }
  }

  /**
   * Checking whether the warning table must be filled or removed
   *
   * @param warning Contains the information to be displayed
   * @param tableID Superordinate tables ID
   */
  createWarningTable(warning: IWarnings, tableID: string): void {
    if (document.getElementById('pdf-keyData-' + tableID + '-fieldname')) {
      // warningd - active
      document.getElementById(
        'pdf-keyData-' + tableID + '-fieldname'
      )!.innerHTML =
        this.usedTranslations['key-data'][TranslationPathPDFConstants.warnings][
        warning.fieldId
        ];

      let messageList = '';
      warning.warningMessages.forEach((message) => {
        let icon =
          message.iconBase64 !== 'ICON_NOT_FOUND' && message.iconBase64 !== ''
            ? '<td><img style="height: 16px; width: 16px;" src="' +
            message.iconBase64 +
            '" alt="warning"></td>'
            : '<td></td>';
        const timestamp = message.timestamp
          ? ' | ' + this.datePipe.transform(message.timestamp, 'dd-MM-YY hh:mm')
          : '';
        messageList =
          messageList + icon + message.message + timestamp + '</td></tr>';
      });

      if (messageList !== '') {
        const text =
          `<table data-pdfmake="{'widths':['*', 'auto'], 'layout': 'noBorders'}"><tbody>` +
          messageList +
          '</tbody></table>';

        var parser = new DOMParser();
        var doc = parser.parseFromString(text, 'text/html').body.childNodes[0];

        document.getElementById('pdf-keyData-' + tableID)!.appendChild(doc);
      }
    }
  }

  /**
   * Checking whether the dtc table must be filled or removed
   *
   * @param diagnosticTroubleCodes Contains the information to be displayed
   */
  createDtcTable(diagnosticTroubleCodes: IDiagnosticTroubleCodes[]): void {
    let diagnosticTroubleCodesList = '';
    diagnosticTroubleCodes.forEach((diagnosticTroubleCode) => {
      let controlUnitFieldName =
        this.usedTranslations['key-data'][
        TranslationPathPDFConstants.diagnosticTroubleCodes
        ][diagnosticTroubleCode.controlUnit.fieldId];

      let addressFieldName =
        this.usedTranslations['key-data'][
        TranslationPathPDFConstants.diagnosticTroubleCodes
        ][diagnosticTroubleCode.address.fieldId];

      diagnosticTroubleCodesList =
        diagnosticTroubleCodesList +
        '<tr><td>' +
        controlUnitFieldName +
        '</td><td class="align-right">' +
        diagnosticTroubleCode.controlUnit.value +
        '</td><td></td> ' +
        '<td>' +
        addressFieldName +
        '</td><td class="align-right">' +
        diagnosticTroubleCode.address.value +
        '</td></tr>';

      if (diagnosticTroubleCode.codes.length > 0) {
        diagnosticTroubleCodesList =
          diagnosticTroubleCodesList +
          `<tr><td colspan="5"><table class="dtc" data-pdfmake="{'widths':['auto','*',30,'auto','*'], 'layout': 'noBorders'}">`;

        diagnosticTroubleCode.codes.forEach((code) => {
          let eventFieldName =
            this.usedTranslations['key-data'][
            TranslationPathPDFConstants.diagnosticTroubleCodes
            ][code.event.fieldId];

          diagnosticTroubleCodesList =
            diagnosticTroubleCodesList +
            '<tr><td><b>' +
            eventFieldName +
            '</b></td><td></td><td class="align-right" colspan="3">' +
            code.event.value +
            '</td></tr>';

          let displayTroubleCodeFieldName =
            this.usedTranslations['key-data'][
            TranslationPathPDFConstants.diagnosticTroubleCodes
            ][code.displayTroubleCode.fieldId];

          let eventStatusFieldName =
            this.usedTranslations['key-data'][
            TranslationPathPDFConstants.diagnosticTroubleCodes
            ][code.eventStatus.fieldId];

          diagnosticTroubleCodesList =
            diagnosticTroubleCodesList +
            '<tr><td><b>' +
            displayTroubleCodeFieldName +
            '</b></td><td class="align-right">' +
            code.displayTroubleCode.value +
            '</td><td></td>' +
            '<td><b>' +
            eventStatusFieldName +
            '</b></td><td class="align-right">' +
            code.eventStatus.value +
            '</td></tr>';

          let timestampFieldName =
            this.usedTranslations['key-data'][
            TranslationPathPDFConstants.diagnosticTroubleCodes
            ][code.timestamp.fieldId];

          let mileageFieldName =
            this.usedTranslations['key-data'][
            TranslationPathPDFConstants.diagnosticTroubleCodes
            ][code.mileage.fieldId];

          diagnosticTroubleCodesList =
            diagnosticTroubleCodesList +
            '<tr><td><b>' +
            timestampFieldName +
            '</b></td><td class="align-right">' +
            this.mapService.mapDateOrPlaceholder(code.timestamp.value) +
            '</td><td></td>' +
            '<td><b>' +
            mileageFieldName +
            '</b></td><td class="align-right">' +
            code.mileage.value +
            ' ' +
            code.mileage.unit +
            '</td></tr>';
        });

        diagnosticTroubleCodesList =
          diagnosticTroubleCodesList + `</table></td></tr>`;
      }
    });

    if (
      diagnosticTroubleCodesList !== '' &&
      document.getElementById('pdf-keyData-diagnosticTroubleCodeEntry')
    ) {
      const text =
        `<table data-pdfmake="{'widths':['auto','*',30,'auto','*'], 'layout': 'noBorders'}"><tbody>` +
        diagnosticTroubleCodesList +
        '</tbody></table>';

      var parser = new DOMParser();
      var doc = parser.parseFromString(text, 'text/html').body.childNodes[0];

      document
        .getElementById('pdf-keyData-diagnosticTroubleCodeEntry')!
        .appendChild(doc);
    }
  }
}
