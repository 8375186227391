import { Injectable } from '@angular/core';
import { IServiceKeyData } from '@utils/interfaces/key-readout/service-key-data/service-key-data.interface';


@Injectable({
  providedIn: 'root'
})
export class ConversionService {

  private readonly KM_TO_MI_CONVERSION = 0.621371;


  constructor() { }


  public convertUnitsToMiles(serviceKeyData: IServiceKeyData): void {
    // Recursive function to traverse and convert units
    const traverseAndConvert = (data: any): void => {
      if (typeof data !== 'object' || data === null) {
        return; // Skip non-object or null fields
      }
  
      // Check if the field is an IFieldIdValuePair and has unit 'km'
      if ('unit' in data && data.unit === 'km') {
        const value = Number(data.value);
        if (!isNaN(value)) {
          data.value = this.convertKilometersToMiles(value);
          data.unit = 'mi';
        }
      }
  
      // If field is a string with implicit km distance (e.g., "10 km"), convert it
      if (typeof data.value === 'string' && data.value.includes('km')) {
        data.value = this.convertTextDistanceToMiles(data.value);
      }
  
      // Recursively traverse nested objects and arrays
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          traverseAndConvert(data[key]);
        }
      }
    };
  
    // Start the traversal from the root object
    traverseAndConvert(serviceKeyData);
  }
  
  // Utility function to convert string distances (e.g., "10 km" to "6 mi") with rounding
  private convertTextDistanceToMiles(text: string): string {
    const kmToMilesConversion = this.KM_TO_MI_CONVERSION;
  
    return text.replace(/(\d+)\s*km/, (match, p1) => {
      const kmValue = parseInt(p1, 10);
      const milesValue = Math.round(kmValue * kmToMilesConversion);
      return `${milesValue} mi`;
    });
  }
  
  // Utility function to convert kilometers to miles with rounding
  public convertKilometersToMiles(value: number): number {
    return Math.round(value * this.KM_TO_MI_CONVERSION);
  }

  // Utility function to convert miles to kilometers with rounding
  public convertMilesToKilometers(value: number): number {
    return Math.round(value / this.KM_TO_MI_CONVERSION);
  }
}
